@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

body {
	font-family: "DM Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
}

.custom-list {
	list-style: none; /* Remove default list styling */
	padding-left: 0; /* Adjust padding as needed */
}

.custom-list li {
	position: relative; /* Needed for absolute positioning of the pseudo-element */
	padding-left: 30px; /* Make room for the icon */
}

.custom-list li::before {
	content: "\f00c"; /* Font Awesome check icon Unicode */
	font-family: "Font Awesome 5 Free"; /* Font Awesome font-family */
	position: absolute;
	left: 0; /* Align with the text start */
	top: 0; /* Adjust as needed */
	color: green; /* Icon color */
	font-weight: 900; /* Ensure the solid style is used */
}

/* YourComponent.css */
.slider-thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 30px; /* Increased size for a bigger thumb */
	height: 30px; /* Increased size for a bigger thumb */
	border-radius: 50%; /* Makes the thumb a circle */
	background: white; /* Thumb color */
	cursor: pointer;
}

.slider-thumb::-moz-range-thumb {
	width: 30px; /* Increased size for a bigger thumb */
	height: 30px; /* Increased size for a bigger thumb */
	border-radius: 50%; /* Makes the thumb a circle */
	background: white; /* Thumb color */
	cursor: pointer;
	border: 2px solid black; /* Optional: adds a border around the thumb */
}

.progress-bar {
	position: fixed;
	top: 0;
	left: 0;
	background: linear-gradient(to right, #ac5cf7, #e91e63, #f8a729);
	color: #fff; /* Change to your desired text color */
	padding: 2px 0; /* Adjust the padding as needed */
	text-align: center;
	z-index: 100; /* Ensure it appears above other content */
	width: 0; /* Initial width is 0 */
}

.sticky-heading {
	position: -webkit-sticky;
	position: sticky;
	top: 1; /* Height of your header */
	z-index: 10; /* Ensure it sits above other content */
	padding-top: 10px;
}
